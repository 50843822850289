import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Typography, CircularProgress, Box, Paper, Button, TextField, Link as MuiLink, Tabs, Tab } from '@mui/material';

const Withdrawals = () => {
  const [withdrawals, setWithdrawals] = useState({ pendingWithdrawals: [], allWithdrawals: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [publicKey, setPublicKey] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [bnbAddress, setBnbAddress] = useState('');
  const [bnbBalance, setBnbBalance] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [txHash, setTxHash] = useState('');

  useEffect(() => {
    const fetchWithdrawals = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No token found, please log in again.');
          setLoading(false);
          return;
        }

        const response = await axios.get('/admin/withdrawals', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setWithdrawals(response.data);
        setPublicKey(response.data.publicKey);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Token is invalid, please log in again.');
        } else {
          setError('Error fetching withdrawals');
        }
        setLoading(false);
      }
    };

    fetchWithdrawals();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSendBNB = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/admin/withdrawals/send-bnb', { fromAddress, toAddress, amount }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTxHash(response.data.txHash);
      alert('BNB sent successfully!');
    } catch (error) {
      setError('Error sending BNB');
    }
  };

  const handleConfirm = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`/admin/withdrawals/confirm/${id}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const response = await axios.get('/admin/withdrawals', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setWithdrawals(response.data);
    } catch (error) {
      setError('Error confirming withdrawal');
    }
  };

  const handleCancel = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`/admin/withdrawals/cancel/${id}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const response = await axios.get('/admin/withdrawals', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setWithdrawals(response.data);
    } catch (error) {
      setError('Error canceling withdrawal');
    }
  };

  const handleCheckBalance = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No token found, please log in again.');
          setLoading(false);
          return;
        }
      const response = await axios.get(`/admin/withdrawals/balance/${bnbAddress}`,{
          headers: { Authorization: `Bearer ${token}` }
        });
      setBnbBalance(response.data.balance);
    } catch (error) {
      setError('Error fetching BNB balance');
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Withdrawals" />
          <Tab label="Send BNB" />        
        </Tabs>

      {tabValue === 0 && (
        <>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
              Withdrawals
            </Typography>
            <Typography variant="body1">
              Public Key: {publicKey}
            </Typography>

            <Typography variant="h5" gutterBottom>
              Pending Withdrawals
            </Typography>
            {withdrawals.pendingWithdrawals.map(withdrawal => (
              <Paper key={withdrawal._id} sx={{ padding: 2, marginBottom: 2 }}>
                <Typography variant="body1">Telegram ID: {withdrawal.userId.telegramId}</Typography>
                <Typography variant="body1">Name: {withdrawal.userId.firstName} {withdrawal.userId.lastName}</Typography>
                <Typography variant="body1">Username: {withdrawal.userId.username}</Typography>
                <Typography variant="body1">Amount: {withdrawal.amount}</Typography>
                <Typography variant="body1">
                  BSC Address: 
                  <MuiLink href={`https://bscscan.com/address/${withdrawal.bscAddress}`} target="_blank" rel="noopener noreferrer">
                    {withdrawal.bscAddress}
                  </MuiLink>
                </Typography>
                <Typography variant="body1">
                  User Profile: 
                  <Link to={`/user/${withdrawal.userId._id}`}>
                    View Profile
                  </Link>
                </Typography>
                <Button variant="contained" color="primary" onClick={() => handleConfirm(withdrawal._id)}>
                  Confirm
                </Button>
                <Button variant="contained" color="secondary" onClick={() => handleCancel(withdrawal._id)}>
                  Cancel
                </Button>
              </Paper>
            ))}

            <Typography variant="h5" gutterBottom>
              All Withdrawals
            </Typography>
            {withdrawals.allWithdrawals.map(withdrawal => (
              <Paper key={withdrawal._id} sx={{ padding: 2, marginBottom: 2 }}>
                <Typography variant="body1">Telegram ID: {withdrawal.userId.telegramId}</Typography>
                <Typography variant="body1">Name: {withdrawal.userId.firstName} {withdrawal.userId.lastName}</Typography>
                <Typography variant="body1">Username: {withdrawal.userId.username}</Typography>
                <Typography variant="body1">Amount: {withdrawal.amount}</Typography>
                <Typography variant="body1">
                  BSC Address:  
                  <MuiLink href={`https://bscscan.com/address/${withdrawal.bscAddress}`} target="_blank" rel="noopener noreferrer">
                    {withdrawal.bscAddress}
                  </MuiLink>
                </Typography>
                <Typography variant="body1">Status: {withdrawal.status}</Typography>
                {withdrawal.status === 'completed' && (
                  <Typography variant="body1">
                    Transaction Hash:  
                    <MuiLink href={`https://bscscan.com/tx/${withdrawal.txHash}`} target="_blank" rel="noopener noreferrer">
                      {withdrawal.txHash}
                    </MuiLink>
                  </Typography>
                )}
                <Typography variant="body1">
                  User Profile: 
                  <Link to={`/user/${withdrawal.userId._id}`}>
                    View Profile
                  </Link>
                </Typography>
              </Paper>
            ))}
          </Box>
        </>
      )}

      {tabValue === 1 && (
        <>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Send BNB
            </Typography>
            <TextField
              label="From Address"
              value={fromAddress}
              onChange={(e) => setFromAddress(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="To Address"
              value={toAddress}
              onChange={(e) => setToAddress(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Amount (BNB)"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleSendBNB}>
              Send BNB
            </Button>
            {txHash && (
              <Typography variant="body2" sx={{ mt: 2 }}>
                Transaction Hash: 
                <MuiLink href={`https://bscscan.com/tx/${txHash}`} target="_blank" rel="noopener noreferrer">
                  {txHash}
                </MuiLink>
              </Typography>
            )}
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Check BNB Balance
            </Typography>
            <TextField
              label="BSC Address"
              value={bnbAddress}
              onChange={(e) => setBnbAddress(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleCheckBalance}>
              Check Balance
            </Button>
            {bnbBalance !== null && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                Balance: {bnbBalance} BNB
              </Typography>
            )}
          </Box>
        </>
      )}

      </Paper>
    </Container>
  );
};

export default Withdrawals;
