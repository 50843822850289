import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Home from './pages/Home';
import Tasks from './pages/Tasks';
import Withdrawals from './pages/Withdrawals';
import Deposits from './pages/Deposits';
import LoginPage from './pages/LoginPage';
import DailyCombo from './pages/DailyCombo';
import ComboDetails from './components/ComboDetails';
import UserDetails from './components/UserDetails';
import TwitterPage from './pages/TwitterPage';
import PigGame from './pages/PigGames';
import VerificationTasks from './pages/VerificationTasks';
import NavBar from './components/NavBar';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4a90e2',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f0f0f5',
    },
  },
});

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);

  const validateToken = async () => {
    try {
      const savedToken = localStorage.getItem('token');
      if (savedToken) {
        const response = await axios.get('/admin/auth/verify-token', {
          headers: { Authorization: `Bearer ${savedToken}` }
        });
        if (response.status === 200) {
          setToken(savedToken);
        } else {
          setToken(null);
          localStorage.removeItem('token');
        }
      } else {
        setToken(null);
      }
    } catch (error) {
      setToken(null);
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      validateToken();
    }, 1000 * 60 * 5); // Verifică token-ul la fiecare 5 minute

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleTokenExpiry = () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setToken(null);
      }
    };

    window.addEventListener('storage', handleTokenExpiry);

    return () => {
      window.removeEventListener('storage', handleTokenExpiry);
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Sau un alt indicator de încărcare
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="App">
          {token && <NavBar />}
          <Routes>
            {token ? (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/withdrawals" element={<Withdrawals />} />
                <Route path="/deposits" element={<Deposits />} />
                <Route path="/user/:id" element={<UserDetails />} />
                <Route path="/verification-tasks" element={<VerificationTasks />} />
                <Route path="/daily-combo" element={<DailyCombo />} />
                <Route path="/pig-game" element={<PigGame />} />
                <Route path="/twitterlist" element={<TwitterPage />} />
                <Route path="/daily-combo/:id" element={<ComboDetails />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            ) : (
              <>
                <Route path="/" element={<LoginPage setToken={setToken} />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
