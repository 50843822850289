import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const NavBar = () => {
  return (
    <AppBar position="static">
      <Container>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Button color="inherit" component={RouterLink} to="/">
            Home
          </Button>
          <Button color="inherit" component={RouterLink} to="/tasks">
            Tasks
          </Button>           
          <Button color="inherit" component={RouterLink} to="/pig-game">
            PigGame
          </Button>          
          <Button color="inherit" component={RouterLink} to="/daily-combo">
            Daily Combo
          </Button>
          <Button color="inherit" component={RouterLink} to="/verification-tasks">
            Pending Tasks
          </Button>
          <Button color="inherit" component={RouterLink} to="/withdrawals">
            Withdrawals
          </Button>
          <Button color="inherit" component={RouterLink} to="/deposits">
            Deposits
          </Button>          
          <Button color="inherit" component={RouterLink} to="/twitterlist">
            Twitter Info
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
